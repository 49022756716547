<template>
  <!-- 新闻资讯 -->
  <div class="news">
    <div class="contain">
      <!-- <Mainpage></Mainpage> -->
      <router-view></router-view>
    </div>
  </div>
</template>

<script>
import Mainpage from "@/views/news/components/mainpage.vue";
export default {
  name: "News",
  components: {
    Mainpage,
  },
  data() {
    return {};
  },
  methods: {},
};
</script>
<style scoped lang="scss">
.news {
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
  .contain {
    // border: 1px solid red;
    // width: calc(100% - 120px);
    width: 1760px;
  }
}
</style>
